import React from 'react';
import Page from '../components/layout/Page';
import Privacy from '../components/layout/privacy/Privacy';

const PrivacyPage = () => (
  <Page title="Privacy Policy">
    <Privacy />
  </Page>
);

export default PrivacyPage;
